import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
// TODO: Remove this import
import heroPhoto from "../MeAndRoxy.jpg";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function Home() {
  const [postData, setPost] = useState(null);
  const noOfTags = 3;

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
            title,
            slug,
            "tagNames": tags[]->title,
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            }
        }`
      )
      .then((data) => setPost(data))
      .catch(console.error);
  }, []);

  return (
    <main className="page-grid-container projects-page">
      <section className="hero-grid-container">
        {/* TODO: Get photo from CDN instead */}
        <div className="dither">
          <picture>
            <img src={heroPhoto} alt="My parents' dog and me" />
          </picture>
        </div>
        <h1>
            <svg viewBox="0 0 250 50" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <path
                  id="HeroTextPath"
                  fill="none"
                  stroke="red"
                  d="m 5, 40 c 100, -50 150, 50 250, -20"
                />
              </defs>
              <text>
                <textPath href="#HeroTextPath">Joseph Tummon</textPath>
              </text>
            </svg>
          </h1>
        <article>
          
          <h3>
            Hi, here's where a <em>very</em> brief intro will go; the longer one
            will go on the About page
          </h3>
        </article>
      </section>
      <div className="hero-bg-fade"></div>
      <h1 className="over-bg-fade">Projects</h1>
      <section className="project-grid-container">
          {postData &&
            postData.map((post, index) => (
                <Link to={"/post/" + post.slug.current} key={post.slug.current} className="project-grid-card">
              
                    {/* @TODO: Add placeholder for loading/missing image */}
                    <img
                      src={urlFor(post.mainImage).width(545).height(350).url()}
                      alt={post.mainImage.alt}
                    />
                    <div className="image-overlay">
                    <article>
                    <h3>{post.title}</h3>
                      {/* Display up to noOfTags tags */}
                      {post.tagNames && post.tagNames.length > 0
                        ? post.tagNames
                            .slice(0, noOfTags)
                            .map((t) => <small key={t} className="tag">{t}</small>)
                        : null}
                      {/* @TODO: Add on an ellipsis if more than noOfTags? */}
                    </article>
                      
                    
                  </div>
              
                </Link>
            ))}
      </section>
    </main>
  );
}

// export default function Home() {
//     return (
//         <div>
//             <h1>Home Page!</h1>
//             <svg className="bar-box" width="50vw" height="200" xmlns="http://www.w3.org/2000/svg">
//                 <defs>
//                 <clipPath id="user-space" clipPathUnits="userSpaceOnUse">
//                     {/* <g className="bar-box-l"> */}
//                         <rect className="bar-box-l" x="1rem" width="75%" height="50" />
//                         <rect className="bar-box-l" x="1rem" y="50%" width="75%" height="50" />
//                     {/* </g>
//                     <g className="bar-box-r"> */}
//                         <rect className="bar-box-r" x="1rem" y="25%" width="75%" height="50" />
//                         <rect className="bar-box-r" x="1rem" y="75%" width="75%" height="50" />
//                     {/* </g> */}
//                 </clipPath>
//                 </defs>
//                 <image clip-path="url(#user-space)" width="100%" height="100%" preserveAspectRatio="xMinYMin slice"  href="https://images.unsplash.com/photo-1508186736123-44a5fcb36f9f?ixlib=rb-0.3.5&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ&s=7ff4199971d35554c59bbf8e0b229534"/>
//             </svg>
//         </div>

//     )
// }
