import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Post from "./components/Post";
import Contact from "./components/Contact";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import "./Fonts.css";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <div className="parallax-container">
        <div className="page-container">
          <Switch>
            <Route component={Home} path="/" exact />
            <Route component={About} path="/about" />
            <Route component={Post} path="/post/:slug" />
            <Route component={Contact} path="/contact" />
          </Switch>
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
