import React from "react";

export default function Contact() {
  return (
    <main className="page-grid-container">
      <section>
        <h1>Contact Page!</h1>
        {/* TODO: Build simple contact form using Azure functions
             and React hooks https://www.isaaclevin.com/post/azure-function-contact-form/
             (That link but with React)*/}
        <ul>
          <li>Contact form</li>
          <li>Email</li>
          <li>Social links (LinkedIn & Github)</li>
        </ul>
      </section>
    </main>
  );
}
