import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function Post() {
  const [post, setPost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
            title,
            _id,
            slug,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            body,
            liveSite,
            gitHub,
            "tagNames": tags[]->title,
            "name": author->name,
            "authorImage": author->image
        }`
      )
      .then((data) => setPost(data[0]))
      .catch(console.error);
  }, [slug]);

  // TODO: Test and update this
  if (!post) return <div>Loading...</div>;

  return (
    <main className="page-grid-container post-page">
        <h1>{post.title}</h1>
        <article className="post-grid-container">
          <img src={urlFor(post.mainImage).url()} alt={post.title} />
          <div>
            <BlockContent
              blocks={post.body}
              projectId="eahel392"
              dataset="production"
            />
            {/* <p>
              Brief outline para (Could also use this for mouseover on
              homepage?)
            </p>
            <p>Tech used, thought process behind it</p>
            <p>Potential evaluation?</p> */}
            
              {post.liveSite ? <a href={post.liveSite}>Live Site</a> : null}
              {post.gitHub ? <a href={post.gitHub}>Source</a> : null}
              {post.tagNames && post.tagNames.length > 0
                        ? <span>{post.tagNames
                            .map((t) => <small key={t} className="tag">{t}</small>)}</span>
                        : null}
            
          </div>          
        </article>
        <div className="post-bg-fade"></div>
    </main>
  );
}
