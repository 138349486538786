import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";

export default function About() {
  const [author, setAuthor] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "author"]{
            name,
            bio,
            "authorImage": image.asset->url
        }`
      )
      .then((data) => setAuthor(data[0]))
      .catch(console.error);
  }, []);

  // TODO: Test and update this
  if (!author) return <div>Loading...</div>;

  return (
    <main className="page-grid-container">
      <section>
        <article>
          <h1>About Page!</h1>
          {/* TODO: Bring in image of me from Sanity author page */}
          <img src={author.authorImage} alt="Me!" />
          <BlockContent
            blocks={author.bio}
            projectId="eahel392"
            dataset="production"
          />
          <p>
            Here's where a longer profile will go! Not too long, but a bit of a
            summary of my CV.
          </p>
          <p>
            I'll have a paragraph about stuff outside of webdev, like game jams,
            MAF and whatever else.
          </p>
          <ul>
            <li>
              Link to contact form page, ("Send me a message here, or contact me
              through email, LinkedIn or GitHub")
            </li>
            <li>
              Link to hosted CV (keeping CV builder as a tool for me, not a
              front-facing thing)
            </li>
          </ul>
        </article>
      </section>
    </main>
  );
}
